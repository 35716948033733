<template>
  <span class="delete-cash-modal" v-if="hasPerm('cash.delete_cashlog')">
    <b-modal
      id="bv-modal-delete-cash"
      cancel-title="Annuler"
      @ok.prevent="onDelete"
      ok-variant="danger"
      ok-title="Confirmation"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-piggy-bank"></i> Suppression  d'une entrée/sortie en caisse</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="cashLog">
        Êtes-vous sûr de vouloir supprimer cette entrée?<br /><br />
        {{ cashLog.logDate | dateToString }} - {{ cashLog.label }} - {{ cashLog.amount | currency }}
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'delete-cash-modal',
  mixins: [BackendMixin],
  props: {
    cashLog: Object,
  },
  data() {
    return {
      errorText: '',
    }
  },
  computed: {
  },
  watch: {
    cashLog: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async onDelete() {
      if (this.cashLog) {
        this.errorText = ''
        try {
          const url = '/api/cash-logs/' + this.cashLog.id + '/'
          const backendApi = new BackendApi('delete', url)
          await backendApi.callApi()
          this.$bvModal.hide('bv-modal-delete-cash')
          this.$emit('done')
        } catch (err) {
          this.errorText = this.getErrorText(err)
          this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>
<style lang="less">
</style>
