<template>
  <span class="deposit-button" v-if="hasPerm('payments.add_deposit')">
    <b-modal dialog-class="modal-xl"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isDepositDisabled()"
      @ok.prevent="onDeposit"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-money-bill-alt"></i> Dépôt</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="4">
          <b-row>
            <b-col>
              <b-form-group
                id="deposit-date-group"
                label="Date de dépôt"
                label-for="deposit-date"
              >
                <b-form-input type="date" id="deposit-date" v-model="depositDate">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="number-group"
                label="Numéro"
                label-for="number"
              >
                <b-form-input id="number" v-model="depositNumber"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="comments-group"
                label="Commentaires"
                label-for="comments"
              >
                <b-form-input id="comments" v-model="comments"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="8">
          <table class="table table-striped table-small">
            <tr v-if="paymentsCount && expensesCount">
              <th colspan="4">Total</th>
              <th class="text-right">{{ depositTotal | currency }}</th>
            </tr>
            <tr><td colspan="5">&nbsp;</td></tr>
            <tr v-if="paymentsCount">
              <th colspan="4"><counter-label :counter="paymentsCount" label="paiement" /></th>
              <th class="text-right">{{ paymentsTotal | currency }}</th>
            </tr>
            <tr class="payment-line" v-for="payment of payments" :key="payment.id">
              <td>
                <span v-if="payment.emitter">{{ payment.emitter }} pour </span>
                {{ payment.entity.name }}
              </td>
              <td>{{ payment.paymentDate | dateToString }}</td>
              <td>{{ payment.bankNumber }}</td>
              <td>{{ payment.bankName }}</td>
              <td class="text-right">{{ payment.amount | currency }}</td>
            </tr>
            <tr v-if="paymentsCount && expensesCount"><td colspan="5">&nbsp;</td></tr>
            <tr v-if="expensesCount">
              <th colspan="4"><counter-label :counter="expensesCount" label="dépense" /></th>
              <th class="text-right">{{ expensesTotal | currency }}</th>
            </tr>
            <tr class="payment-line" v-for="expense of expenses" :key="expense.id">
              <td>{{ expense.label }}</td>
              <td>{{ expense.getDate() | dateToString }}</td>
              <td colspan="2"></td>
              <td class="text-right">{{ expense.amount | currency }}</td>
            </tr>
            <tr v-if="expensesCount && refundsCount"><td colspan="5">&nbsp;</td></tr>
            <tr v-if="refundsCount">
              <th colspan="4"><counter-label :counter="refundsCount" label="remboursement" /></th>
              <th class="text-right">{{ refundsTotal | currency }}</th>
            </tr>
            <tr class="payment-line" v-for="refund of refunds" :key="refund.id">
              <td><span v-if="refund.entity">{{ refund.entity.name }}</span></td>
              <td>{{ refund.createdOn | dateToString }}</td>
              <td colspan="2"></td>
              <td class="text-right">{{ refund.amount | currency }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'

export default {
  name: 'create-deposit-modal',
  components: { CounterLabel, },
  mixins: [BackendMixin],
  props: {
    payments: Array,
    expenses: Array,
    refunds: Array,
    label: {
      type: String,
      default: 'Dépôt',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      depositDate: null,
      depositNumber: '',
      comments: '',
      errorText: '',
    }
  },
  computed: {
    depositTotal() {
      return this.paymentsTotal - this.expensesTotal - this.refundsTotal
    },
    paymentsTotal() {
      return this.payments.reduce(
        (accumulator, payment) => accumulator + payment.amount,
        0
      )
    },
    expensesTotal() {
      return this.expenses.reduce(
        (accumulator, expense) => accumulator + expense.amount,
        0
      )
    },
    refundsTotal() {
      return this.refunds.reduce(
        (accumulator, refund) => accumulator + refund.amount,
        0
      )
    },
    paymentsCount() {
      return this.payments.length
    },
    expensesCount() {
      return this.expenses.length
    },
    refundsCount() {
      return this.refunds.length
    },
    depositCount() {
      return this.paymentsCount + this.expensesCount + this.refundsCount
    },
  },
  watch: {
    payments: function(newValue, oldValue) {},
    expenses: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    getId() {
      if (this.id) {
        return this.id
      } else {
        return 'bv-modal-make-deposit'
      }
    },
    isDepositDisabled() {
      return this.payments.length === 0 && this.expenses.length === 0 && this.refunds.length === 0
    },
    async onDeposit() {
      this.errorText = ''
      try {
        const url = '/api/deposits/'
        const backendApi = new BackendApi('post', url)
        let data = {
          payments: this.payments.map(elt => elt.id),
          expenses: this.expenses.map(elt => elt.id),
          refunds: this.refunds.map(elt => elt.id),
          comments: this.comments,
          number: this.depositNumber,
          deposit_on: '' + moment(this.depositDate).format('YYYY-MM-DD'),
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        this.$emit('deposit')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
    this.depositDate = moment().format('YYYY-MM-DD')
  },
}
</script>
<style lang="less">
  .table-small tr td, .table-small tr th {
    padding: 2px;
    border-bottom: solid 1px #f0f0f0;
    margin: 2px;
    font-size: 10px;
  }
  .table-small tr th {
    background: #ccc;
  }
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
