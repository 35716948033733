<template>
  <span class="cash-modal" v-if="hasPerm('cash.add_cashlog')">
    <b-modal
      id="bv-modal-cash"
      cancel-title="Annuler"
      :ok-disabled="isPayDisabled()"
      @ok.prevent="onPay"
      ok-variant="primary"
      :ok-title="getTitle()"
      @show="onShow"
      size="xl"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-piggy-bank"></i>
          <span v-if="transferBank">Remise en banque</span>
          <span v-else>Saisie d'une entrée/sortie en caisse</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="payment-mode-group"
            label="Mode de paiement"
            label-for="payment-mode"
            :disabled="allPaymentModes.length < 2"
          >
            <b-form-select v-model="selectedPaymentMode" id="payment-mode">
              <b-select-option v-for="elt of allPaymentModes" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="!transferBank">
          <b-form-group label="Type de saisie" label-for="entryType">
            <b-form-radio v-model="entryType" name="entryType" value="1" class="inline">Recette</b-form-radio>
            <b-form-radio v-model="entryType" name="entryType" value="2" class="inline">Dépense</b-form-radio>
          </b-form-group>
        </b-col>
        <b-col v-else>
          <b-form-group label="Type de saisie" label-for="entryType">
            <b-form-radio disabled v-model="entryType" name="entryType" value="2" class="inline">Transfert</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="comments-group"
            label="Libellé"
            label-for="label"
          >
            <b-form-input id="label" v-model="label" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="date-group"
            label="Date"
            label-for="date"
            description=""
          >
            <b-form-input type="date" id="date" v-model="date" @change="onDateChanged">
            </b-form-input>
            <div v-if="dateError" class="cash-date-error">
              {{ dateError }}
            </div>
            <div v-if="dateWarning" class="cash-date-warning">
              {{ dateWarning }}
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="date-group"
            label="Heure"
            label-for="time"
            description=""
          >
            <b-form-input type="time" id="time" v-model="time">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="selectedPaymentMode && selectedPaymentMode.bank">
          <b-form-group
            id="bank-group"
            description="Banque"
            label-for="bank-name"
          >
            <vue-bootstrap-typeahead
              id="bank-name"
              v-model="bankName"
              :data="banks"
              ref="bankNameTypeAhead"
              @hit="onBankSelected"
              :minMatchingChars="2"
              :disableFiltering="true"
            >
            </vue-bootstrap-typeahead>
          </b-form-group>
        </b-col>
        <b-col v-if="selectedPaymentMode && selectedPaymentMode.bank">
          <b-form-group
            id="bank-number-group"
            :description="'Numéro' + (selectedPaymentMode.isNumberRequired ? ' (obligatoire)' : '')"
            label-for="bank-number"
          >
            <b-form-input
              id="bank-number"
              v-model="bankNumber"
              :required="selectedPaymentMode.isNumberRequired"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="selectedPaymentMode && selectedPaymentMode.showEmitter">
          <b-form-group id="emitter-group" description="Émetteur" label-for="emitter">
            <b-form-input id="emitter" v-model="emitter"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isExpense">
        <b-col>
          <b-form-group
            id="reference-group"
            label="Pièce comptable"
            label-for="reference"
          >
           <b-form-input v-model="reference" id="reference"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-for="line of lines" :key="line.id">
        <b-col>
          <b-form-group
            label="Libellé additionnel"
            :label-for="'label' + line.id"
            required
          >
            <b-form-input id="'label' + line.id" type="text" v-model="line.label"></b-form-input>
            <a href v-if="line.id > 0" @click.prevent="removeLine(line)" class="small-text">
              <i class="fa fa-trash"></i> supprimer
            </a>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Montant en €"
            :label-for="'amount' + line.id"
            required
          >
            <decimal-input :id="'amount' + line.id" v-model="line.amount">
            </decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Compte général"
            :label-for="'general-account' + line.id"
          >
            <b-form-select v-model="line.generalAccount" :id="'general-account' +  line.id">
              <b-select-option v-for="elt of generalAccounts" :key="elt.id" :value="elt">
                {{ elt.name }}<span v-if="elt.label"> - {{ elt.label }}</span>
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="!transferBank">
          <b-form-group
            label="Compte analytique"
            :label-for="'analytic-account' + line.id"
          >
            <b-form-select v-model="line.analyticAccount" :id="'analytic-account' + line.id">
              <b-select-option v-for="elt of analyticAccounts" :key="elt.id" :value="elt">
                {{ elt.label || elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <a href @click.prevent="addLine()" class="small-text">
        <i class="fa fa-plus-square"></i> ajouter
      </a>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'

export default {
  name: 'cash-modal',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    allAnalyticAccounts: Array,
    allGeneralAccounts: Array,
    allPaymentModes: Array,
    transferBank: Boolean,
  },
  data() {
    return {
      label: '',
      errorText: '',
      entryType: 1,
      selectedPaymentMode: null,
      date: '',
      time: '',
      bankName: '',
      bankNumber: '',
      emitter: '',
      reference: '',
      banks: [],
      nextLineId: 0,
      lines: [],
    }
  },
  computed: {
    isExpense() {
      return !this.transferBank && (+this.entryType === 2)
    },
    dateError() {
      const now = moment()
      if (!this.date) {
        return 'Date manquante'
      }
      const duration = moment.duration(now.diff(moment(this.date)))
      if (duration.asDays() < 0) {
        return 'La date ne peut pas être future'
      }
      return ''
    },
    dateWarning() {
      const now = moment()
      if (this.date) {
        const duration = moment.duration(now.diff(moment(this.date)))
        if (duration.asDays() > 30) {
          return 'La date est très antérieure à la date du jour. Vérifier qu\'il n\'y a pas d\'erreur.'
        }
      }
      return ''
    },
    analyticAccounts() {
      return [makeAnalyticAccount()].concat(this.allAnalyticAccounts.filter(
        elt => elt.showForCash
      ))
    },
    totalAmount() {
      return sum(this.lines.map(line => +line.amount))
    },
    generalAccounts() {
      let generalAccounts = this.allGeneralAccounts
      if (generalAccounts) {
        const entryType = +this.entryType
        if (entryType === 1) {
          // credit
          generalAccounts = generalAccounts.filter(elt => elt.isPayment)
        } else if (entryType === 2) {
          // débit
          if (this.transferBank) {
            generalAccounts = generalAccounts.filter(elt => elt.isTransfer)
          } else {
            generalAccounts = generalAccounts.filter(elt => elt.isExpense)
          }
        }
        return [makeGeneralAccount()].concat(generalAccounts)
      }
      return []
    },
  },
  mounted() {
    if (this.transferBank) {
      this.entryType = 2 // Dépôt en banque = 'Dépense'
      this.label = 'Dépôt en banque'
    } else {
      this.entryType = 1
      this.label = ''
    }
  },
  watch: {
    transferBank: function() {
      if (this.transferBank) {
        this.entryType = 2 // Dépôt en banque = 'Dépense'
        this.label = 'Dépôt en banque'
      } else {
        this.entryType = 1
        this.label = ''
      }
    },
    selectedPaymentMode() {
      this.bankName = ''
      this.bankNumber = ''
      this.emitter = ''
    },
    entryType: function(entryType) {
      for (const line of this.lines) {
        if (line.generalAccount && this.generalAccounts.length) {
          let doesntMatch = false
          if ((+entryType === 1) && !line.generalAccount.isPayment) {
            doesntMatch = true
          }
          if ((+entryType === 2) && !line.generalAccount.isExpense) {
            doesntMatch = true
          }
          if (doesntMatch) {
            line.generalAccount = this.generalAccounts[0]
          }
        }
      }
    },
    allPaymentModes: function() {
      if (this.allPaymentModes.length === 1) {
        this.selectedPaymentMode = this.allPaymentModes[0]
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    isPayDisabled() {
      let disabled = (this.lines.length === 0) || (!this.label) || (!this.date) || (!this.selectedPaymentMode)
      if (!disabled && this.selectedPaymentMode && this.selectedPaymentMode.bank) {
        disabled = (
          !this.bankName ||
          (this.selectedPaymentMode.isNumberRequired && !this.bankNumber)
        )
      }
      if (!disabled && this.dateError) {
        disabled = true
      }
      if (!disabled) {
        for (const line of this.lines) {
          if (!line.amount) {
            disabled = true
          }
        }
      }
      return disabled
    },
    async onPay() {
      this.errorText = ''
      try {
        const url = '/api/cash-logs/'
        const backendApi = new BackendApi('post', url)
        const entryType = +this.entryType
        const time = this.time ? (this.time + ':00') : '00:00:00'
        let data = {
          label: this.label,
          date: moment(this.date).format('YYYY-MM-DD') + 'T' + time,
          payment_mode: this.selectedPaymentMode.id,
          transfer_bank: this.transferBank,
          bank_name: this.bankName,
          emitter: this.emitter,
          bank_number: this.bankNumber,
          reference: this.reference,
          is_expense: (entryType === 2),
          lines: this.lines.map(
            line => {
              return {
                label: line.label || '',
                amount: (entryType === 1) ? line.amount : -line.amount,
                general_account: line.generalAccount.id,
                analytic_account: line.analyticAccount.id,
              }
            }
          ),
        }
        await backendApi.callApi(data)
        this.$bvModal.hide('bv-modal-cash')
        this.selectedPaymentMode = null
        if (this.allPaymentModes.length === 1) {
          this.selectedPaymentMode = this.allPaymentModes[0]
        }
        this.date = moment().format('YYYY-MM-DD')
        this.label = ''
        this.reference = ''
        this.lines = this.getFirstLine()
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    getFirstLine() {
      return [
        {
          id: 0,
          amount: 0,
          label: '',
          generalAccount: makeGeneralAccount(),
          analyticAccount: makeAnalyticAccount(),
        }
      ]
    },
    getTitle() {
      if (this.totalAmount) {
        const entryType = +this.entryType
        if (this.transferBank) {
          return 'Ajouter une dépôt de ' + currency(this.totalAmount)
        } else if (entryType === 1) {
          return 'Ajouter une recette de ' + currency(this.totalAmount)
        } else {
          return 'Ajouter une dépense de ' + currency(this.totalAmount)
        }
      }
      return 'Ajouter'
    },
    async loadBanks() {
      this.banks = []
    },
    onBankSelected(evt) {},
    onDateChanged() {
      this.time = null
    },
    onShow() {
      this.date = moment().format('YYYY-MM-DD')
      this.time = moment().format('HH:mm')
      this.lines = this.getFirstLine()
    },
    addLine() {
      this.nextLineId += 1
      this.lines = this.lines.concat(
        {
          id: this.nextLineId,
          amount: 0,
          label: '',
          generalAccount: makeGeneralAccount(),
          analyticAccount: makeAnalyticAccount(),
        }
      )
    },
    removeLine(line) {
      const index = this.lines.map(elt => elt.id).indexOf(line.id)
      if (index >= 1) {
        // on ne supprime jamais la première ligne
        this.lines.splice(index, 1)
        this.lines = [].concat(this.lines)
      }
    },
  },
}
</script>
<style scoped lang="less">
.inline {
  display: inline-block;
  margin-right: 10px;
}
.cash-date-error {
  font-size: 12px;
  padding: 5px 3px;
  background: #f78888;
}
.cash-date-warning {
  font-size: 12px;
  padding: 5px 3px;
  background: #efcb64;
}
</style>
